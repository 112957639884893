<template>
  <div class="section-title mx-[16px] lg:mb-6">
    <template v-if="loading">
      <div class="flex flex-col gap-2">
        <div class="h-4 w-32 bg-gray-300 lg:h-6"></div>
        <div class="hidden h-6 w-72 bg-gray-300 lg:block"></div>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-between">
        <div class="flex items-center">
          <div
            class="mr-2 inline-block h-[12px] w-[3px] flex-grow-0 rounded-full bg-red-dark lg:mr-1"
          ></div>
          <div
            class="flex w-full items-center truncate text-base font-bold text-black-primary"
          >
            {{ title }}
          </div>
        </div>
        <div class="flex gap-2">
          <h2 v-if="tagLine" class="hidden text-xl font-medium text-[#656C6F]">
            {{ tagLine }}
          </h2>
          <a
            v-if="link"
            class="flex cursor-pointer items-center justify-center rounded-lg border border-[#FFE2E2] px-2 py-0 text-[12px] font-semibold capitalize leading-5 text-red-dark"
            :href="link"
            @click.prevent="onClick(link)"
            >{{ $t("more") }}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    required: true,
  },
  tagLine: {
    type: String,
    default: "",
  },
  link: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

defineOptions({
  name: "HomeSectionTitle",
});

const emits = defineEmits<{
  (e: "on-link-clicked", link: string): void;
}>();

function onClick(link: string) {
  emits("on-link-clicked", link);
}
</script>
